<template>
    <!-- Logo cloud -->
    <div class="bg-gray-100">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
					<p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
							Trusted by carriers worldwide
					</p>
					<div class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4">
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-16" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/viaero_grey.png" alt="Viaero Wireless">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-16" src="https://newayatradeinapp.s3-us-west-2.amazonaws.com/union_wireless_gray_sm.png" alt="Union Wireless">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-16" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/inland_grey.png" alt="Inland Cellular">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/cvt_grey.png" alt="Copper Valley Telecom">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-12" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/astac.png" alt="ASTAC">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/docomo_grey.png" alt="Docomo Pacific">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-16" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/gta_gray.png" alt="GTA">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/thumb_gray.png" alt="Thumb Cellular">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/Strata%20Grey%20Logo%20(1)-min.png" alt="Strata Networks">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/Pine%20Belt%20Grey%20Logo.png" alt="Pine Belt Wireless">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/IT&E%20Grey%20Logo-min.png" alt="IT&E">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/Bravado%20Wireless%20Grey%20Logo.png" alt="Bravado Wireless">
							</div>
							<!-- 4th row -->
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3.us-west-2.amazonaws.com/nemont_grey_logos_for_website.png" alt="Nemont">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3.us-west-2.amazonaws.com/CusterTel%20Grey%20Logos%20For%20Website.png" alt="CusterTel">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3.us-west-2.amazonaws.com/PTCI%20Grey%20Logos%20For%20Website.png" alt="PTCI">
							</div>
							<div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
									<img class="h-14" src="https://newayatradeinapp.s3.us-west-2.amazonaws.com/Grey%20Logos%20For%20Website.png" alt="CellularOneNE">
							</div>
					</div>
        </div>
    </div>
</template>